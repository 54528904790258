<template>
  <div class="tc-user_information">
    <!-- 顶部功能 -->
    <!-- <div class="header-tool">
      <div class="del" @click="delDatas">
        <img src="../../../assets/images/del.png" alt />
      </div>
      <div class="refresh" @click="refresh()">
        <img src="../../../assets/images/refresh.png" alt />
      </div>
    </div> -->
    <!-- <div class="filtrate">
      <div class="header-title">
        <p>筛选</p>
      </div>
      <div class="header-from">
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          size="mini"
          ref="formInline"
        >
          <el-form-item label="部门" prop="bumen">
            <el-select v-model="formInline.bumen">
              <el-option
                v-for="item in departmentList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                >{{ item.name }}</el-option
              >
            </el-select>
          </el-form-item>
          <el-form-item label="性别" prop="sex">
            <el-select v-model="formInline.sex">
              <el-option label="男" value="1"></el-option>
              <el-option label="女" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="出生范围" prop="date">
            <div class="twoData">
              <el-date-picker
                v-model="formInline.agemin"
                type="date"
                placeholder="开始日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
              至
              <el-date-picker
                v-model="formInline.agemax"
                type="date"
                placeholder="结束日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </div>
          </el-form-item>
          <el-form-item label="姓名" prop="user">
            <el-input v-model="formInline.user"></el-input>
          </el-form-item>
          <el-form-item label="文化程度" prop="wenhua">
            <el-select v-model="formInline.wenhua">
              <el-option label="文盲" value="文盲"></el-option>
              <el-option label="小学" value="小学"></el-option>
              <el-option label="初中" value="初中"></el-option>
              <el-option
                label="高中/中专/技校"
                value="高中/中专/技校"
              ></el-option>
              <el-option label="大学专科" value="大学专科"></el-option>
              <el-option label="大学本科" value="大学本科"></el-option>
              <el-option label="硕士" value="硕士"></el-option>
              <el-option label="博士及以上" value="博士及以上"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit()">查询</el-button>
            <el-button @click="resetForm('formInline')">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div> -->

    <div class="top-information-management">
      <div style="display:flex;align-items: center;">
        <div class="title-text">用户信息管理</div>
        <div class="account-box">
          <el-form
            :inline="true"
            :model="formInline"
            class="demo-form-inline"
            size="mini"
            ref="formInline"
          >
            <el-form-item  prop="user" >
              <el-input v-model="formInline.user" placeholder="输入姓名"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit()">查询</el-button>
              <!-- <el-button @click="resetForm('formInline')">重置</el-button> -->
          </el-form-item>
          </el-form>
        </div>
        <el-tooltip content="展开" placement="top" effect="light">
        <div class="icon-box icon-box1">
         <i class="icon-a-Filter3" @click="open"></i>
        </div>
        </el-tooltip>
        <el-tooltip content="删除" placement="top" effect="light">

        <div @click="delDatas" class="icon-box icon-box1">
         <i class="icon-Delete"></i>
        </div>
        </el-tooltip>

      </div>   
      <div v-show="openFlag" style="display:flex;align-items: center;" class="search-box">
        <div  class="from-box">
          <el-form
            :inline="true"
            :model="formInline"
            class="demo-form-inline"
            size="mini"
            ref="formInline"
            :label-position="labelPosition"
          >
            <el-form-item label="部门" prop="bumen">
              <el-select v-model="formInline.bumen" clearable>
                <el-option
                  v-for="item in departmentList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  >{{ item.name }}</el-option
                >
              </el-select>
            </el-form-item>
            <el-form-item label="性别" prop="sex">
              <el-select v-model="formInline.sex" clearable>
                <el-option label="男" value="1"></el-option>
                <el-option label="女" value="0"></el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="出生范围" prop="date">
              <div class="twoData">
                <el-date-picker
                  v-model="formInline.agemin"
                  type="date"
                  placeholder="开始日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
                至
                <el-date-picker
                  v-model="formInline.agemax"
                  type="date"
                  placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </div>
            </el-form-item> -->
            <!-- <el-form-item label="姓名" prop="user">
              <el-input v-model="formInline.user"></el-input>
            </el-form-item> -->
            <el-form-item label="文化程度" prop="wenhua">
              <el-select v-model="formInline.wenhua" clearable>
                <el-option label="文盲" value="文盲"></el-option>
                <el-option label="小学" value="小学"></el-option>
                <el-option label="初中" value="初中"></el-option>
                <el-option
                  label="高中/中专/技校"
                  value="高中/中专/技校"
                ></el-option>
                <el-option label="大学专科" value="大学专科"></el-option>
                <el-option label="大学本科" value="大学本科"></el-option>
                <el-option label="硕士" value="硕士"></el-option>
                <el-option label="博士及以上" value="博士及以上"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <!-- <el-button @click="resetForm('formInline')">重置</el-button> -->
            </el-form-item>
          </el-form>
        </div>
        <el-button class="ok-btn" size="mini" @click="onSubmit()">筛选</el-button>  
      </div> 
    </div>
    <!-- 表格 -->
    <div class="content">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        max-height="680"
        @selection-change="handleSelectionChange"
        stripe
        border
        class="tableBox"
        v-loading="loading"
      >
        <el-table-column
          type="selection"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="account"
          label="用户编号"
          width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="department_name"
          label="部门名称"
          width="160"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="real_name"
          label="姓名"
          width="160"
          align="center"
        ></el-table-column>
        <el-table-column prop="sex" label="性别" width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.sex | sexFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column label="家庭情况" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="plain"
              @click="openSurface(scope.row, 1)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="学习经历" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="plain"
              @click="openSurface(scope.row, 2)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="成功与失败经历" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="plain"
              @click="openSurface(scope.row, 3)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="自我评价" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="plain"
              @click="openSurface(scope.row, 4)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="困惑与烦恼" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="plain"
              @click="openSurface(scope.row, 5)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="plain" @click="openRecord(scope.row)"
              >查看</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button
              size="mini"
              type="plain"
              @click="shwoEditDialog(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 页码 -->
    <div class="page">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="pageSize"
        :page-sizes="[15, 30, 50, 100, 200, 400]"
        layout="total,sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { loadDepartmentData } from "@/api/comm.js";
import { loadUserData, delUser } from "@/api/evaluate.js";
import { getArchives, listArchives } from "@/api/information.js";
import { log } from "util";
export default {
  inject: ["reload"],
  data() {
    return {
      openFlag:false,
      labelPosition: 'top',
      loading: true,
      //筛选表单
      formInline: {
        user: "",
        region: "",
        sex: "",
        bumen: "",
        chushenL: "",
        wenhua: "",
        date: " ",
        agemin: "",
        agemax: "",
      },
      // 部门
      departmentList: [],
      form: {
        date: "",
      },
      tableData: [],
      multipleTable: "",
      total: 0,
      page: 1,
      pageSize: 15,
      editDialog: false, //控制编辑模态框
      imageUrl: "",
      multipleSelection: [],
    };
  },
  created() {
    // 获取所有部门
    loadDepartmentData().then((res) => {
      this.departmentList = res.data.data;
    });
    this.loadUsers();
  },
  methods: {
    // 展开更多搜索
    open(){
      this.openFlag=!this.openFlag
    },
    resetForm(formName) {
      this.page = 1;
      this.$nextTick(() => {
        this.formInline.agemin = "";
        this.formInline.agemax = "";
        this.$refs[formName].resetFields();
      });
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    // 页码监听
    handleCurrentChange(val) {
      // 根据page加载对应数据
      this.page = val;
      this.loadUsers(val);
    },
    // 刷新
    refresh() {
      this.reload();
    },
    // 获取全选ID
    handleSelectionChange(val) {
      this.multipleSelection = val;
      let p = [];
      this.multipleSelection.forEach((v) => {
        p.push(v.id);
      });
      this.multipleSelection = p;
      //console.log(p);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.loadUsers();
      //console.log(`每页 ${val} 条`);
    },
    // 用户列表查询/请求数据
    async loadUsers(page) {
      // 数据处理
      let {
        user,
        region,
        sex,
        bumen,
        chushenL,
        wenhua,
        agemin,
        agemax,
      } = this.formInline;
      let data = {
        page: page || 1, //默认第一页
        real_name: user,
        pagesize: this.pageSize,
        sex: sex,
        agemin: agemin || "",
        agemax: agemax || "999999999999",
        department_id: bumen,
        culture: wenhua,
      };
      // 发送数据
      loadUserData(data).then((res) => {
        this.tableData = res.data;
        this.total = res.count;
        this.loading = false;
      });
    },
    // 打开表页面
    openSurface(row, val) {
      //console.log(row);
      this.$router.push(`/user_information/surface?id=${row.id}&type=${val}`);
    },
    // 打开档案报表面
    openRecord(row) {
      //console.log(row);
      this.$router.push(`/user_information/record?id=${row.id}`);
    },
    // 显示编辑模态框
    shwoEditDialog(row) {
      this.$router.push(`/user_evaluate/editUser?id=${row.id}`);
    },
    // 提交
    onSubmit() {
      this.page = 1;
      this.loadUsers();
      this.$message({
        message: "查询成功",
        type: "success",
      });
    },
    // 全选删除
    delDatas() {
      //console.log(this.multipleSelection);

      // 判断是否有数据
      if (this.multipleSelection.length <= 0) {
        this.$message({
          showClose: true,
          message: "请先勾选要删除的数据",
          type: "warning",
        });
        return;
      }
      //  提示 是否删除
      this.$confirm("是否删除已勾选的数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = this.multipleSelection;
          data = data.toString();
          delUser({ id: data }).then((res) => {
            if (res.code === 400200) {
              this.$message({
                message: "批量删除成功",
                type: "success",
              });
            }
            this.loadUsers();
          });
        })
        .catch(() => {});
    },
  },
  filters: {
    // 性别过滤器
    sexFilter(val) {
      return val == 0 ? "女" : "男";
    },
  },
};
</script>
<style lang="less">

.top-information-management{
  width: 100%;
  min-height: 72px;
  margin-top: 10px;
  border: 1px solid #EBEEF5;
  background-color: #fff;
  // display:flex;
  // align-items: center;
  box-sizing: border-box;
  padding: 10px;
  position: relative;
  
  .title-text{
    font-size: 18px;
    font-weight: bold;
    margin: 0 40px;
  }
  .account-box{
    margin-top: 20px;
  }
  // 默认模式
  .icon-box{
    width: 32px;
    height: 32px;
    border-radius: 4px;
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
  .icon-box1{
    background-color: #E2E1E1;
    i{
      color: #808080;
    }
  }
  .icon-box1:hover{
    background-color: #FF8F42;
    i{
      color: #FFFFFF;
    }
  }
  // 点击模式
  .icon-box2{
    background-color: #E2E1E1;
    i{
      color: #808080;
    }
  }
  .right-btn-box{
    position:absolute;
    right: 40px;

    // 右边部分按钮
    .btn{
      background-color: #FF8F42;
      border: none;
      margin-left: 10px;
      .icon{
        margin-right: 4px;
      }
    }
  }
  // 搜索
  .search-box{
    height: 90px;
    border: 1px solid #BFBFBF;
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    overflow: hidden;
    .from-box{
      margin-top: 18px;
    }
    .ok-btn{
      background-color: #FF8F42;
      color: #fff;
      border: none;
      margin-top: 21px;
      margin-left: 30px;
    }
  }

}
.tc-user_information {
  .header-tool {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .del {
      width: 100px;
      margin-right: 20px;
      cursor: pointer;
    }
    .refresh {
      width: 100px;
      cursor: pointer;
    }
    .user_informationBtn {
      width: 130px;
      margin-right: 20px;
    }
    img {
      width: 100%;
      pointer-events: none;
    }
  }
  .twoData {
    display: flex;
    .el-date-editor {
      width: unset;
      .el-input__inner {
        width: 140px !important;
      }
    }
  }
  // 筛选
  // 筛选
  .filtrate {
    display: flex;
    width: 100%;
    height: 50px;
    background: rgb(217, 242, 247);
    margin-top: 20px;
    .header-title {
      color: rgb(4, 87, 102);
      width: 56px;
      text-align: center;
      border-right: 1px solid rgb(127, 222, 243);
    }
    p {
      line-height: 50px;
      font-size: 14px;
    }
    .header-from {
      padding-left: 10px;
      display: flex;
      align-items: center;
      .el-input__inner {
        width: 80px;
        padding-right: 20px;
      }
      .el-form-item:nth-child(1) .el-input__inner {
        width: 150px;
        padding-right: 0;
      }
      .el-form-item:nth-child(3) .el-input__inner {
        width: 230px;
        padding-right: 0;
      }
      .el-form-item {
        margin-bottom: 0;
      }
      .el-form-item__label {
        color: rgb(4, 87, 102);
      }
      .el-input__icon {
        width: 15px;
      }
    }
  }
  // .filtrate {
  //   display: flex;
  //   width: 100%;
  //   height: 100px;
  //   background: rgb(217, 242, 247);
  //   margin-top: 20px;
  //   .header-title {
  //     color: rgb(4, 87, 102);
  //     width: 56px;
  //     height: 100%;
  //     text-align: center;
  //     border-right: 1px solid rgb(127, 222, 243);
  //     p {
  //       line-height: 100px;
  //       font-size: 16px;
  //     }
  //   }

  //   .header-from {
  //     padding-left: 10px;
  //     display: flex;
  //     align-items: center;
  //     .el-row:nth-child(2) {
  //       margin-top: 8px;
  //       .el-form-item:nth-child(6) .el-input__inner {
  //         width: 180px;
  //         padding-right: 0;
  //       }
  //     }
  //     .el-input__inner {
  //       width: 150px;
  //       padding-right: 20px;
  //     }
  //     .el-form-item {
  //       margin-bottom: 0;
  //     }
  //     .el-form-item__label {
  //       color: rgb(4, 87, 102);
  //     }
  //     .el-input__icon {
  //       width: 15px;
  //     }
  //     .el-button--mini {
  //       padding: 5px 10px;
  //       background: transparent;
  //       color: rgb(4, 87, 102);
  //       font-size: 16px;
  //       &:hover {
  //         border: 1px solid rgb(105, 105, 105);
  //       }
  //     }
  //   }
  // }
  //   表格
  .content {
    position: relative;
    height: calc(~"100% - 220px");
    min-height: 580px;
    margin-top: 10px;
    color: rgb(4, 87, 102);
    th {
      // color: rgb(4, 87, 102);
      padding: 0;
      height: 40px;
      line-height: 40px;
      // background: rgb(217, 242, 247);

      color: #4B2000 !important;
      background: #FFDEC6 !important;
      border-right: none !important;
      border-left: none !important;
    }
    td {
      padding: 0;
      height: 35px;
      line-height: 35px;

      border-right: none !important;
      border-left: none !important;
    }
    tr.el-table__row--striped td {
      background: rgb(234, 246, 248);
    }
    .el-button + .el-button {
      margin-left: 0px;
    }
    .el-button--mini {
      // padding: 4px 4px;
      // background: transparent;
      // color: rgb(4, 87, 102);
      // font-size: 14px;
      // &:hover {
      //   border: 1px solid rgb(105, 105, 105);
      // }
      color: #FFAB71 !important;
      padding: 4px 4px;
      background: transparent;
      border: none !important;
      font-size: 14px;
    }
    .no {
      color: green;
    }
    .off {
      color: red;
    }
    .el-input__inner {
      width: 50px;
      padding: 5px;
      text-align: center;
    }
  }
  // 页码
  .page {
    // .el-pagination {
    //   position: absolute;
    //   bottom: 0;
    //   right: 0;
    // }
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  .el-dialog__wrapper {
    .el-form-item:nth-child(1) .el-input__inner {
      width: 150px;
      padding-right: 0;
    }
    .el-form-item:nth-child(2) .el-input__inner {
      width: 150px;
      padding-right: 0;
    }
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 80px;
      height: 80px;
      line-height: 80px;
      text-align: center;
    }
    .avatar {
      width: 80px;
      height: 80px;
      display: block;
    }
  }
}
// 展开搜索项间距
.el-form-item__label {
  font-weight: bold;
  line-height: 0px !important;
}
// 带牌
// table{
//   color: #100700;
//     th {
//       color: #4B2000 !important;
//       background: #FFDEC6 !important;
//       border-right: none !important;
//       border-left: none !important;
//     }
//     td {
//       border-right: none !important;
//       border-left: none !important;
//     }
//     .el-button--mini{
//       color: #FFAB71 !important;
//       padding: 4px 4px;
//       background: transparent;
//       border: none !important;
//       font-size: 14px;
//     }
// }
</style>